import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row, Container } from "react-bootstrap"
import Button from "../components/button/button"
const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="property-info-wrapper terms-blk sitemap">
      <Container className="property-info">
        <Row>
          <div className=" page-404">
            <h1>We’re sorry, the page you were looking for cannot be found.</h1>
            <div className="property-info__content pt-5">
            <p>This maybe because it has been removed, we’ve changed its name or it is temporarily unavailable.</p>
            <Row className="intro-content__cta">
            <Col xs="12" md="6" className={"mb-4 pe-md-3 mb-md-0"}>
            <Button link={"/"} color={"yellow"}>
            <Button.Icon>
                  <i className={`icon icon--house`} />
                </Button.Icon>
                <Button.Text>Back to Home</Button.Text>
                </Button>
              </Col>
              <Col xs="12" md="6" className={"mb-4 ps-md-4 mb-md-0"}>
              <Button link={"/about/our-branches"} color={"blue"}>
              <Button.Icon>
                  <i className={`icon icon--arrow-up-right`} />
                </Button.Icon>
                <Button.Text>Contact</Button.Text>
                </Button>
              </Col>
              
            </Row>
            </div>
            </div>
        </Row>

      </Container>
    </div>
  </Layout>
)

export default NotFoundPage
